.formCard {
    z-index: 10;
    background-color: rgba(255,255,255,.5);
    margin: 0 auto;
    padding: 50px;
}

.exchangeForm {
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: 10;
}

h2 {
    z-index: 10;
    font-size: 3rem;
}

input {
    margin: 15px;
    padding: 10px;
    font-size: 1rem;
    font-family: 'Permanent Marker', cursive;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.postal-check {
    display: flex;
    justify-content: center;
}

.checkbox {
    margin: 5px;
}

.submit {
    background-color: rgba(12, 148, 62, .5);
    margin: 20px;
    padding: 10px;
    border: none;
    border-radius: 12px;
}

@media only screen and (max-width: 1500px) {
    .formCard {
        padding: 0;
    }

    .exchangeForm {
        padding: 0;
    }

    h2 {
        font-size: 2rem;
        margin: 10px 0;
        padding: 20px;
    }

    input {
        font-size: 1rem;
        padding: 5px;
    }
}


@media only screen and (max-width: 600px) {
    .formCard {
        padding: 0;
        width: 75%;
    }

    .exchangeForm {
        padding: 0;
    }

    h2 {
        font-size: 1.5rem;
        margin: 10px 0;
    }

    input {
        font-size: 1rem;
        padding: 5px;
    }
}