.adminPageComponents {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: 11;
    margin: 0 auto;
    padding: 10px;
}

.start {
    background-color: rgba(163, 35, 26, .75);
    margin: 20px;
    padding: 10px;
    border: none;
    border-radius: 12px;
    z-index: 11;
    font-size: 2rem;
    color: white;
    font-family: 'Permanent Marker', cursive;
}

.pair-check {
    display: flex;
    justify-content: center;
    z-index: 10;
    width: 50%;
    font-size: 1.5rem;
    background-color: rgba(255,255,255,.5);
    padding: 20px;
}

.checkbox {
    margin: 5px;
}