.currentPageComponents {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: 11;
    margin: 0 auto;
    padding: 10px;
    width: 50%;
}

.exchangeDetails {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: 11;
    background-color: rgba(255,255,255,.5);
    margin: 0 auto;
    padding: 20px;
    width: 50%;
}

.participantContainer {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: 11;
    background-color: rgba(255,255,255,.5);
    margin: 0 auto;
    padding: 20px;
    width: 50%;
    margin-bottom: 20px;
}

.exchangeTitle {
    padding: 0;
}

.participantList {
    margin: 10px;
}

.participant {
    margin: 0;
}

@media only screen and (max-width: 600px) {
    .currentPageComponents {
        width: 100%;
    }

    .exchangeTitle {
        padding: 10px;
        margin: 10px;
    }

    .participantList {
        padding: 0;
    }
}
